<template>
  <a-layout class="customerStatus">
    <!-- 语言选择 -->
    <a-select
      @change="handleChange"
      class="language-box"
      placeholder="language"
      v-model:value="languageVal"
    >
      <a-select-option value="en">English</a-select-option>
      <a-select-option value="fr">Français</a-select-option>
      <a-select-option value="sp">Español</a-select-option>
      <a-select-option value="zh">简体中文</a-select-option>
      <a-select-option value="tc">繁體中文</a-select-option>
      <a-select-option value="ja">日本語</a-select-option>
      <a-select-option value="ar">بالعربية</a-select-option>
    </a-select>
    <a-layout-header>
        <a-image :width="130" :height="auto" :preview="false" src="https://weappimg.apm-monaco.cn/197/logo-transparent.png" />
        <h2>{{ tFn('customerStatus.title') }}</h2>
    </a-layout-header>
    <a-layout-content>
        <div class="search_layout">
            <div class="search_content middle1200">
                <!-- <h1 class="search_title">{{ tFn('customerStatus.clickText') }}</h1> -->
                <a-steps v-model:current="currentNum" :initial="0" size="small" labelPlacement="vertical" :direction="direction" style='margin-top: 30px;margin-bottom: 50px;'>
                    <a-step
                        v-for="(item, index) in stepList"
                        :key="index"
                        :title="tFn(`customerStatus.${item.title}`)"
                        disabled
                        :description="tFn(`customerStatus.${item.description}`)"
                        :icon="currentNum >= index ? h(CheckCircleOutlined) : h(MinusCircleOutlined)"
                    />
                </a-steps>
                <!-- 参数说明 -->
                <div class="search_explain">
                    <a-row :gutter="30">
                        <a-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11" style="display: flex;align-items: center;justify-content: center;">
                            <a-image style="display: block;width: 60%;margin: 0 auto 20px;box-shadow: 2px 2px 20px #cccccc;" :src="productUrl" />
                        </a-col>
                        <a-col :xs="24" :sm="13" :md="13" :lg="13" :xl="13">
                            <div class="explain_item">
                                <span style="width: 230px;font-size: 22px;">{{ tFn('customerStatus.apmSale') }}:</span>
                                <h2 style="font-size: 20px;">{{ dataObj.RmaID }}</h2>
                            </div>
                            <div class="explain_item_flex" style="margin-top: 30px;">
                                <span>{{ tFn('customerStatus.orderID') }}:</span>
                                <h3>{{dataObj.origin_invoice_number}}</h3>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.orderName') }}:</span>
                                <p>{{dataObj.order_number}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.trackingNumber') }}:</span>
                                <p>{{dataObj.retail_carrier_code}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.productReference') }}:</span>
                                <p>{{dataObj.product_sku}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.userName') }}:</span>
                                <p>{{dataObj.lastname}}{{dataObj.firstname}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.userEmail') }}:</span>
                                <p>{{dataObj.email}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.userPhone') }}:</span>
                                <p>+{{dataObj.phone_code}}-{{dataObj.phone}}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.shopName') }}:</span>
                                <p>{{ dataObj.shopName }}</p>
                            </div>
                            <div class="explain_item_flex" v-if="dataObj.RmaStatusHistory">
                                <span>{{ tFn('customerStatus.created') }}:</span>
                                <p>{{ formatDateWithTime(dataObj.RmaStatusHistory[dataObj.RmaStatusHistory.length - 1].CreatedAt) + " (UTC)" }}</p>
                            </div>
                            <div class="explain_item_flex">
                                <span>{{ tFn('customerStatus.repairFee') }}:</span>
                                <p>{{ dataObj.repair_fee }} {{ dataObj.repair_fee > 0 ? dataObj.repair_fee_ccy : '' }}</p>
                            </div>
                            <div class="explain_item_flex" v-if="dataObj.rma_item_to_reason && dataObj.rma_item_to_reason.length != 0">
                                <span>{{ tFn('customerStatus.repairReason') }}:</span>
                                <p v-for="reason in dataObj.rma_item_to_reason" :key="reason.ID">
                                    <b>{{ tFn("reasonsName."+covertToCamelCase(reason.Name)) }}</b>
                                </p>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </a-layout-content>
    <a-layout-footer>
        <div class="footer_container">
            <p>
                <HomeFilled style="fontSize: 18px;" />
                <span><a href="https://www.apm.mc/" style="color: #ffffff;">{{ tFn('customerStatus.enjoyText') }}</a></span>
            </p>
            <p v-if="!isMobile()">|</p>
            <p>
                <MessageFilled style="fontSize: 18px;" />
                <span>{{ tFn('customerStatus.tipsText') }}</span>
            </p>
            <!-- <p v-if="!isMobile()">|</p>
            <p>
                <PhoneFilled style="fontSize: 18px;" />
                <span>Live Chat | Whatsapp (+33) 7 57 90 33 93 | customercare@apm.mc</span>
            </p> -->
        </div>
    </a-layout-footer>
  </a-layout>
</template>

<script setup>
    import { defineComponent, computed, onMounted, ref, watch, h} from "vue";
    import { i18n2 } from '@/lang/index'
    import { useRoute } from 'vue-router'
    import request from '@/utils/axios';
    import { statusRMA } from "@/services/auth";
    import { formatDateWithTime } from '@/utils/utils';
    import { useStore } from 'vuex'
    import { HomeFilled, MessageFilled, PhoneFilled, CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";


    const route = useRoute();
    const store = useStore()
    const tFn = i18n2.global.t;
    
    const languageVal = ref('fr');
    const rmaID = ref('');
    const language = ref('');
    const dataObj = ref({});
    const productUrl = ref('');
    const RmaStatusHistory = ref([]);
    const currentNum = ref(0);
    const stepList = ref([
        {
            title: 'step1Title',
            description: 'step1Content',
        },
        {
            title: 'step2Title',
            description: 'step2Content',
        },
        {
            title: 'step3Title',
            description: 'step3Content',
        },
        {
            title: 'step4Title',
            description: 'step4Content',
        },
        {
            title: 'step5Title',
            description: 'step5Content',
        },
        {
            title: 'step6Title',
            description: 'step6Content',
        }
    ]);
    const direction = ref('horizontal')

    rmaID.value = route.query.rma_id;
    language.value = route.query.language;

    const getFn = async () => {
        const res = await statusRMA(rmaID.value);
        dataObj.value = res.data[0];
        productUrl.value = `https://imgcdn.apm-monaco.cn/products-images/${dataObj.value.product_sku}.jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76`
        RmaStatusHistory.value = res.data[0].RmaStatusHistory;
        currentNum.value = parseInt(res.data[0].Progress) - 1;

        switch (language.value) {
            case 'ENU':
                languageVal.value = 'en';
                break;
            case 'CHS':
                languageVal.value = 'zh';
                break;
            case 'CHT':
                languageVal.value = 'tc';
                break;
            case 'DEU':
                languageVal.value = 'en';
                break;
            case 'ESP':
                languageVal.value = 'sp';
                break;
            case 'FRA':
                languageVal.value = 'fr';
                break;
            case 'ITA':
                languageVal.value = 'en';
                break;
            case 'JPN':
                languageVal.value = 'ja';
                break;
            default:
                languageVal.value = 'en';
                break;
        }
        handleChange(languageVal.value)
    }
    onMounted(async() => {
        if(isMobile()){
            direction.value = 'vertical'
        }else{
            direction.value = 'horizontal'
        }
        getFn();
    })

    const handleChange = value => {
      console.log(`selected ${value}`);
      i18n2.global.locale = value;
      if(value === 'ar'){
        document.body.dir = 'rtl';
      }else{
        document.body.dir = 'ltr';
      }
    };

    // watch(locale, (newValue, oldValue) => {
    //     store.dispatch('app/setLanguage', locale.value)
    // }) 
    
    
    const handleLangSelect = (lang) => {
        i18n2.global.locale = lang
    }

    const isMobile = () => {
        let isMobile = false;
        if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            // console.log('移动端');
            isMobile = true;
        }
        if (document.body.clientWidth < 800) {
            isMobile = true;
        }
        return isMobile
    }

    const covertToCamelCase = (str) => {
        return str.replace(/\s/g, "")
    }
</script>
<style lang="scss">
.customerStatus{
    .language-box{
        width: 120px;
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #001529;
    }
    .ant-layout-header{
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        h2{
            margin: 0;
            padding: 0;
            color: #ffffff;
            font-size: 32px;
            margin-left: 30px;
            font-weight: 600;
        }
    }
    .search_layout{
        overflow: hidden;
        background-color: #f8f8f8;
        .search_content{
            padding: 30px 0;
            width: 1200px;
            margin: 10px auto;
            .ant-image{
                width: 100%;
            }
            .search_title{
                font-size: 30px;
                text-align: center;
                padding: 20px 0;
                margin-top: 30px;
            }
            .search_explain{
                margin: 50px auto 0;
                box-sizing: border-box;
                padding: 20px;
                background-color: #ffffff;
                .explain_item{
                    display: flex;
                    align-items: center;
                    span{
                        width: 190px;
                        box-sizing: border-box;
                        padding: 0 0 0 20px;
                        font-size: 24px;
                        font-weight: 600;
                    }
                    h2{
                        font-size: 24px;
                        margin: 0;
                        padding: 0;
                    }
                    i{
                        color: #0072d1;
                        font-style: normal;
                        margin-top: 10px;
                        display: inline-block;
                    }
                }
                .price_item{
                    margin-top: 15px;
                    background-color: #f8f8f8;
                }
                .explain_item_flex{
                    display: flex;
                    align-items: center;
                    align-content: center;
                    padding: 15px 0;
                    border-bottom: 1px solid #e8e8e9;
                    span{
                        width: 190px;
                        box-sizing: border-box;
                        padding: 0 0 0 20px;
                    }
                    h3{
                        flex: 1;
                        color: #0072d1;
                        padding: 0;
                        margin: 0;
                    }
                    p{
                        padding: 0;
                        margin: 0;
                        b{
                            display: block;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .ant-layout-footer{
        background-color: #001529;
        .footer_container{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        p{
            color: #ffffff;
            margin: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                margin-left: 5px;
            }
        }
    }
}
@media only screen and (max-width:768px){
    .customerStatus{
        .language-box{
            width: 100px;
        }
        .ant-layout-header{
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2{
                font-size: 18px;
                margin-left: 0;
                text-align: center;
                line-height: 40px;
                margin-top: 10px;
            }
        }
        .search_layout{
            .search_content{
                width: 92% !important;
            }
            .search_title{
                font-size: 20px !important;
            }
            .search_explain{
                margin-top: 10px !important;
                .explain_item{
                    display: flex;
                    span{
                        width: 130px !important;
                        box-sizing: border-box;
                        padding: 0 0 0 5px !important;
                        font-size: 18px !important;
                        font-weight: 600;
                    }
                    h2{
                        font-size: 18px !important;
                        margin: 0;
                        padding: 0;
                    }
                }
                .explain_item_flex{
                    display: flex;
                    align-items: center;
                    align-content: center;
                    padding: 15px 0;
                    border-bottom: 1px solid #e8e8e9;
                    span{
                        width: 130px !important;
                        box-sizing: border-box;
                        padding: 0 0 0 5px !important;
                    }
                    h3{
                        flex: 1;
                        color: #0072d1;
                        padding: 0;
                        margin: 0;
                    }
                    p{
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
            margin-top: 10px !important;
            margin-bottom: 15px !important;
        }
        .ant-layout-footer{
            background-color: #001529;
            .footer_container{
                width: 100%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            p{
                color: #ffffff;
                margin: 10px 0px;
            }
        }
    }
    
}
</style>