import { createI18n } from 'vue-i18n';
import storageUtils from '../utils/storage';

import enLocale from './en';
import zhLocale from './zh';
import tcLocale from './tc';
import frLocale from './fr';
import spLocale from './sp';
import jaLocale from './ja';
import arLocale from './ar';

import antEnLocale from 'ant-design-vue/lib/locale/en_US.js'; 
import antCnLocale from 'ant-design-vue/lib/locale/zh_CN.js'; 


const messages = {
  en: {
    ...enLocale,
    ...antEnLocale,
  },
  zh: {
    ...zhLocale,
    ...antCnLocale,
  },
  tc: {
    ...tcLocale
  },
  fr: {
    ...frLocale
  },
  sp: {
    ...spLocale
  },
  ja: {
    ...jaLocale
  },
  ar: {
    ...arLocale
  }
};

export function getLanguage() {
  const chooseLanguage = storageUtils.getUserLang();
  if (chooseLanguage) {
    return chooseLanguage;
  }

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      storageUtils.setUserLang(locale);
      return locale;
    }
  }
  storageUtils.setUserLang('en');
  return 'en';
}

const i18n = new createI18n({
  locale: getLanguage(),
  messages,
});

export const i18n2 = new createI18n({
  locale: 'en',
  messages,
});

export default i18n;

