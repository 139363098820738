export default {
    customerStatus:{
      title: 'طلب إصلاح APM Monaco',
      apmSale: 'خدمة ما بعد البيع',
      customerName: 'اسم العميل',
      shopName: 'متجر',
      shopPhone: 'هاتف المتجر',
      shopEmail: 'عنوان البريد الإلكتروني',
      created: 'تم إنشاؤه',
      productReference: 'مرجع',
      repairFee: 'رسوم الإصلاح',
      repairReason: 'سبب الإصلاح',
      clickText: 'اضغط هنا لمتابعة إصلاحك',
      enjoyText: 'استمتع بالتسوق على www.apm.mc',
      tipsText: 'نحن متوفرون على مدار 24 ساعة في اليوم، 7 أيام في الأسبوع',
      step1Title: 'الخطوة ١ ',
      step2Title: 'الخطوة ٢',
      step3Title: 'الخطوة ٣',
      step4Title: 'الخطوة ٤',
      step5Title: 'الخطوة ٥',
      step6Title: 'الخطوة ٦',
      step1Content: 'إيداع إلى بوتيك APM',
      step2Content: 'نقل إلى مركز الإصلاح',
      step3Content: 'مركز الإصلاح',
      step4Content: 'نقل إلى متجر APM',
      step5Content: 'جاهز للاستلام',
      step6Content: 'تم استلامه',
      orderID: 'رقم الطلب',
      orderName: 'معرف الطلب',
      trackingNumber: 'رقم التتبع',
      userName:'الاسم',
      userEmail: 'بريد إلكتروني',
      userPhone: 'هاتف',
    }
  };
  