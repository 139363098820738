<template>
  <div>
    <a-card :bordered="true" :body-style="{ padding: '24px 32px' }">
      <a-form
        :model="formState"
        :rules="rules"
        ref="formRef"
      >
        <a-row class="form-row" :gutter="24">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="firstname"
              ref="firstname"
              :label="$t('route.FirstName')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :class="isSearch ? '' : 'requiredFormIcon'"
            >
              <a-input v-model:value="formState.firstname" :disabled="!isSearch" />
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="lastname"
              ref="lastname"
              :label="$t('route.LastName')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :class="isSearch ? '' : 'requiredFormIcon'"
            >
              <a-input v-model:value="formState.lastname" :disabled="!isSearch" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="phone"
              ref="phone"
              :label="$t('route.Phone')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input-group compact >
                <a-select
                  v-if="isSearch"
                  v-model:value="formState.phone_code"
                  show-search
                  :filterOption="filterOption"
                  option-label-prop="value"
                  style="width: 40%"
                >
                  <a-select-option v-for="option in CountryCodeData" :key="option.ISO_3" :value="option.phone_code" :label="option.label" :title="option[`${locale}_name`]">{{ option.label }}</a-select-option>
                </a-select>
                <a-input style="width: 25%" v-if="!isSearch" v-model:value="formState.phone_code" :disabled="isChinaShop || !isSearch"/>
                <a-input :style="`width: ${!isSearch ? '75%' : '60%'};`" v-model:value="formState.phone" :disabled="!isSearch" />
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="email"
              ref="email"
              :label="$t('route.Email')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :class="isSearch ? '' : 'requiredFormIcon'"
            >
              <a-input v-model:value="formState.email" :disabled="!isSearch" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24" v-if="isShowToCustomer">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="toCustomer"
              ref="toCustomer"
              label=" "
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              class="no_start lable_no"
            >
             <a-checkbox v-model:checked="isShowDeliveryInfo" :disabled="isMustSendToCustomer" @change="handleChecked">{{ $t('route.DeliverToCustomer') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider orientation="center" v-if="isShowDeliveryInfo">{{ $t('route.mailingInformation') }}</a-divider>
        <!-- 新增邮寄信息 -->
        <a-row class="form-row" :gutter="24" v-if="isShowReceiver">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="receiver_name"
              ref="receiver_name"
              :label="$t('route.receiverName')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model:value="formState.receiver_name" />
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="receiver_phone"
              ref="receiver_phone"
              :label="$t('route.receiverPhone')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model:value="formState.receiver_phone" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24" v-if="isShowDeliveryInfo">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}" v-if="isOverSeaBoutique">
            <a-form-item
              name="customer_country_code"
              ref="customer_country_code"
              :label="$t('createRMA.Country')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-select ref="country_select" v-model:value="formState.customer_country_code" show-search>
                <!-- @change="handleBoutiqueCountry" -->
                <a-select-option v-for="country in boutiqueCountryList" :key="country.value" :value="country.iso_code_3" :label="country.en_name" :disabled="!country.is_shippable">
                  <span>{{ country.en_name }}</span>
                  <span class="boutique_country_red" v-if="country.is_shippable">{{ t('createRMA.Allow') }}</span>
                  <span class="boutique_country_green" v-else>{{ t('createRMA.Deny') }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}" v-if="!isOverSeaBoutique">
            <a-form-item
              name="province_value"
              ref="province_value"
              :label="$t('route.province')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-cascader v-model:value="formState.province_value" @change="handleCascader" :options="provinceOptionsArr" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="customer_address"
              ref="customer_address"
              :label="$t('route.address')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model:value="formState.customer_address" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24" v-if="isShowDeliveryInfo">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="postalCode"
              ref="postalCode"
              :label="$t('route.postalCode')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              class="no_start"
            >
              <a-input v-model:value="formState.customer_postal_code" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="24" v-if="isShowDeliveryInfo">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="isAgent"
              ref="isAgent"
              label=" "
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              class="no_start lable_no"
            >
             <a-checkbox v-model:checked="isShowReceiver">{{ $t('route.isAgent') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        
        <!-- <a-row class="form-row" :gutter="24">
          <a-col :lg="{span: 12}" :md="{span: 12}" :sm="{span: 12}">
            <a-form-item
              name="order_number"
              ref="order_number"
              :label="$t('productDetail.orderNumber')"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input v-model:value="formState.order_number" />
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- Products Table -->
        <!-- Display if not search by Invoice or Search by Customer -->
        <a-button v-if="isSearch" type="primary" @click="EnableModalAddProduct" style="margin-bottom: 8px">{{ $t("route.addProduct") }}</a-button>

        <a-list :pagination="false" >
          <a-list-item :key="index" v-for="(item, index) in rmaProducts">
            <div class="list-content">
              <div class="list-content-item">
                <a-image width="100px" size="large" shape="square" :src="item.avatar"/>
                <p style="font-weight: bold;">{{ item.name }} (Size {{ item.size }})</p>
                <p><span>{{ $t("route.Warranty") }} </span> <span v-if="item.warranty" style="font-weight: bold;">{{ $t("route.UnderWarranty") }}</span>  <span v-else style="font-weight: bold;"> {{ $t("route.Expired") }}</span></p>
              </div>
            </div>
            <div class="list-content" style="width: 500px;">
              <div class="list-content-item requiredforitem" style="width:100%;">
                <!-- <p><span>{{ $t("route.Invoice") }} </span> <span style="font-weight: bold;">{{ item.origin_invoice_number }}</span></p> -->
                <p v-if="item.isGold">
                  <a-form-item
                    name="sequence_no"
                    ref="sequence_no"
                    :label="$t('productDetail.SequenceNo')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-input-search placeholder="" v-model:value="item.sequenceNo" :enter-button="$t('route.Check')" @search="checkSequenceNo(item, index)" class="sequence_no_input">
                      <template #prefix>
                        <a-button type="primary" @click="toggleScanner(index)">
                          <template #icon>
                            <ScanOutlined />
                          </template>
                        </a-button>
                      </template>
                    </a-input-search>
                  </a-form-item>
                </p>
                <div v-if="item.certInfo.SmallBagNo">
                  <p class="gold_product_info">{{ $t('productDetail.goldProductInfo') }}</p>
                  <p>
                    <a-form-item
                      :label="$t('productDetail.SequenceNo')"
                      :label-col="{ span: 10 }"
                      :wrapper-col="{ span: 14 }"
                    >
                      <div class="cart_info">{{ item.certInfo.SmallBagNo }}</div>
                    </a-form-item>
                  </p>
                  <p>
                    <a-form-item
                      :label="$t('productDetail.CaratWeight')"
                      :label-col="{ span: 10 }"
                      :wrapper-col="{ span: 14 }"
                    >
                      <div class="cart_info">{{ item.certInfo.TotalCaratWeight }} {{ $t("productDetail.Carat") }}</div>
                    </a-form-item>
                  </p>
                  <p>
                    <a-form-item
                      :label="$t('productDetail.GoldWeight')"
                      :label-col="{ span: 10 }"
                      :wrapper-col="{ span: 14 }"
                    >
                      <div class="cart_info">{{ item.certInfo.GoldWeight }} {{ $t("productDetail.Grams") }}</div>
                    </a-form-item>
                  </p>
                  <p>
                    <a-form-item
                      name="gold_weight_before_repaired"
                      ref="gold_weight_before_repaired"
                      :label="$t('productDetail.totalWeight')"
                      :label-col="{ span: 10 }"
                      :wrapper-col="{ span: 14 }"
                      class="requiredFormIcon"
                    >
                      <a-input v-model:value="item.TotalWeight" />
                    </a-form-item>
                  </p>
                </div>
                <p>
                  <a-form-item
                    name="origin_invoice_number"
                    ref="origin_invoice_number"
                    :label="$t('productDetail.orderID')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-input :disabled="!isSearch" v-model:value="item.origin_invoice_number" />
                  </a-form-item>
                </p>
                <p>
                  <a-form-item
                    name="order_number"
                    ref="order_number"
                    :label="$t('productDetail.orderNumber')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-input :disabled="!isSearch" v-model:value="item.order_number" />
                  </a-form-item>
                </p>
                <p>
                  <a-form-item
                    name="dateOfPurchase"
                    ref="dateOfPurchase"
                    :label="$t('productDetail.Date')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-date-picker :disabled="!isSearch || isBlankRMA" v-model:value="item.dateOfPurchase" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="ChangeExpiryDate(item)" />
                  </a-form-item>
                </p>
                <p v-if="isOverSeaBoutique && isShowDeliveryInfo">
                  <a-form-item
                    name="shipping_fee"
                    ref="shipping_fee"
                    :label="$t('productDetail.ShippingFee')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                  >
                    {{ formState.shipping_fee }} {{ formState.shipping_fee_ccy }}
                  </a-form-item>
                </p>
                <p>
                  <a-form-item
                    name="repair_fee"
                    ref="repair_fee"
                    :label="$t('productDetail.RepairFee')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-select ref="select" v-model:value="item.repair_fee" disabled>
                      <a-select-option v-for="amount in item.amountList" :key="amount.value" :value="amount.value">{{ amount.label }}</a-select-option>
                    </a-select>
                    <a-checkbox class="repair_fee_checkbox" v-if="!item.warranty || item.showWaivedFee || isShowDeliveryInfo" v-model:checked="item.waived_fee_status" @change="confirmWaivedFee($event, item)">{{ $t('route.RequestWaivedFee') }}</a-checkbox>
                  </a-form-item>
                </p>
                <p v-if="(!item.warranty || item.showWaivedFee || isShowDeliveryInfo) && !item.waived_fee_status">
                  <a-form-item
                    name="invoice_number"
                    ref="invoice_number"
                    :label="$t('productDetail.InvoiceNumber')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-input-search
                      v-if="isShowDeliveryInfo && isOverSeaBoutique"
                      v-model:value="item.invoice_number"
                      :enter-button="t('route.Check')"
                      @search="onCheckInvoice(item)"
                      :disabled="item.checkedInvoiceIsTrue"
                      :loading="item.checkedInvoiceLoading"
                    />
                    <a-input v-model:value="item.invoice_number" v-else />
                  </a-form-item>
                </p>
                <p v-if="isBlankRMA">
                  <a-form-item
                    name="country"
                    ref="country"
                    :label="$t('createRMA.Country')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-select ref="country_select" v-model:value="item.country" show-search optionFilterProp="label">
                      <a-select-option v-for="country in countryList" :key="country.value" :value="country.value" :label="country.label">{{ country.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </p>
                <p v-if="isBlankRMA">
                  <a-form-item
                    name="city"
                    ref="city"
                    :label="$t('createRMA.City')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-input v-model:value="item.city" />
                  </a-form-item>
                </p>
                <p v-if="isBlankRMA">
                  <a-form-item
                    name="language"
                    ref="language"
                    :label="$t('createRMA.Language')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-select ref="country_select" v-model:value="item.language" show-search optionFilterProp="label">
                      <a-select-option v-for="language in languageList" :key="language.value" :value="language.value" :label="language.label">{{ language.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </p>
                <p v-if="isBlankRMA">
                  <a-form-item
                    name="fulfilledDate"
                    ref="fulfilledDate"
                    :label="$t('createRMA.FulfilledDate')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-date-picker v-model:value="item.fulfilledDate" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
                  </a-form-item>
                </p>
                <p v-if="isBlankRMA">
                  <a-form-item
                    name="type"
                    ref="type"
                    :label="$t('createRMA.Type')"
                    :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    class="requiredFormIcon"
                  >
                    <a-select ref="select" v-model:value="item.type">
                      <a-select-option value="DOUYIN">DOUYIN</a-select-option>
                      <a-select-option value="JD">JD</a-select-option>
                      <a-select-option value="RED">RED</a-select-option>
                      <a-select-option value="RETAIL">RETAIL</a-select-option>
                      <a-select-option value="TMALL">TMALL</a-select-option>
                      <a-select-option value="WEBSITE">WEBSITE</a-select-option>
                      <a-select-option value="WECHAT">WECHAT</a-select-option>
                    </a-select>
                  </a-form-item>
                </p>
              </div>
            </div>
            <div class="list-content">
              <div class="list-content-item">
                <p>
                  <span class="requiredspan">{{ $t("route.Reasons") }} </span>
                  <ul style="list-style-type:none;">
                    <li v-for="reason in item.reasons" :key="reason.ID2" style="margin-bottom: 8px;">
                      <!-- <a-checkbox class="checkbox-reasons" v-if="reason.checked" v-model:checked="reason.checked" disabled >{{ reason.Name }}</a-checkbox> -->
                      <input
                        id="reas"
                        name="reas"
                        type="checkbox"
                        class="checkbox-reasons"
                        v-if="reason.checked"
                        :checked="reason.checked"
                        disabled
                        :value="reason.Name"
                      />
                      <label v-if="reason.checked" for="reas" class="checkbox-reasons">{{ item.renewFlag && reason.in_warranty ? $t("reasonsName.Renew") + ': ' : '' }}{{ $t("reasonsName."+covertToCamelCase(reason.Name)) }}</label>
                    </li>
                  </ul>
                  <a-button type="primary" shape="round" @click="showModalReasons(item)">{{ $t("route.Select") }}</a-button>
                </p>
                <p>
                  <span class="requiredspan">{{ $t("route.Uploads") }} </span>
                  <ul style="list-style-type:none;">
                    <li v-for="image in item.uploadImage" :key="image.name">
                      <span>{{ image.name }}</span>
                    </li>
                  </ul>
                  <input
                    type="file"
                    id="upload_hidden_files"
                    shape="round"
                    @change="uploadFile($event, item)"
                    class="custom-file-input"
                    multiple
                    accept="image/png, image/jpeg"
                    :data-content="chooseFileName()"
                  >
                </p>
                <p v-if="item.isGold">
                  <span class="requiredspan">{{ $t("route.UploadTotalWeight") }} </span>
                  <ul style="list-style-type:none;">
                    <li v-for="image in item.uploadGoldWeightImage" :key="image.name">
                      <span>{{ image.name }}</span>
                    </li>
                  </ul>
                  <input
                    type="file"
                    id="upload_hidden_files"
                    shape="round"
                    @change="uploadGoldWeightFile($event, item)"
                    class="custom-file-input"
                    multiple
                    accept="image/png, image/jpeg"
                    :data-content="chooseFileName()"
                  >
                </p>
              </div>
            </div>
            <div class="list-content comments">
              <div class="list-content-item item-comments">
                <!-- <p>Comments</p> -->
                <a-textarea v-model:value="item.comments" showCount :maxlength="120" :rows="4" :placeholder="t('route.StoreRemark')" />
              </div>
            </div>
          </a-list-item>
        </a-list>
        <!-- End Products Table -->

        <a-form-item style="float:right;">
          <a-button type="primary" @click="cancel()" style="margin-right: 10px">{{ $t("permission.cancel") }}</a-button>
          <a-button type="primary" @click="createRma2()" :loading="loadingCreate" :disabled="isClickCreate">{{ $t('route.Create') }}</a-button>
        </a-form-item>
      </a-form>
    </a-card>
    <a-modal
      v-model:visible="modalAddProduct"
      :title="$t('route.addProduct')"
      centered
      @ok="handleAdd"
      wrapClassName=""
      style="min-width: 650px;"
    >
      <a-form :model="formState">
        <!-- Product name -->
        <a-form-item
          label="SKU"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 5 }, sm: { span: 17 } }"
        >
          <!-- <a-input v-model:value="newProduct.name" /> -->
          <a-auto-complete
            v-model:value="newProduct.name"
            :options="options"
            style="width: 200px"
            :placeholder="$t('route.Search')"
            @select="onSelect"
            @search="onSearch"
          />
        </a-form-item>

        <!-- Product size -->
        <a-form-item
          :label="$t('route.size')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 5 }, sm: { span: 17 } }"
        >
          <a-select
            v-model:value="newProduct.size"
            style="width: 200px"
            :placeholder="$t('route.Select')"
            option-label-prop="label"
          >
            <a-select-option v-for="element in modalProductSize" :key="element.size_code" :value="element.size_code" :label="element.size_code">
              {{ element.size_code }}
            </a-select-option>
          </a-select>
          <!-- <a-input v-model:value="" /> -->
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="showReasonsModal" :title="$t('route.selectRepairReason')" :maskClosable="false" width="700px">
      <template #footer>
        <p></p>
        <a-button key="back" @click="cancelSelectReasons">{{ $t("permission.cancel") }}</a-button>
        <a-button key="submit" type="primary" @click="submitSelectReasons">{{ $t("permission.submit") }}</a-button>
      </template>
      <!-- <a-col class="reason_title" v-if="modalReasons.isOutlet" >{{ $t('route.noWarranty') }}</a-col> -->
      <a-row>
        <a-col :span="modalReasons.renewFlag || modalReasons.isGoldRing ? 8 : 12">
          <!-- <a-row class="reason_title" v-if="!modalReasons.isOutlet">{{ $t('route.noWarranty') }}</a-row> -->
          <a-row class="reason_title">{{ $t('route.noWarranty') }}</a-row>
          <a-row
            v-for="element in modalReasons.reasons"
            :key="element.ID2"
            :label="element.Name"
          >
            <label class="PillList-item" v-if="!element.in_warranty">
              <input
                type="checkbox"
                name="feature"
                :checked="element.checked"
                :disabled="element.is_disabled || element.is_truely_disabled"
                @click="ReasonCheckbox(element, element.ID, modalReasons)"
              >
              <span :class="element.is_disabled ? 'PillList-label disable' : 'PillList-label'">{{ $t("reasonsName." + covertToCamelCase(element.Name)) }}
                <span class="Icon Icon--checkLight Icon--smallest"><CheckOutlined /></span>
              </span>
            </label>
          </a-row>
        </a-col>
        <a-col :span="modalReasons.isGoldRing ? 9 : 12" v-show="!modalReasons.renewFlag">
          <!-- <a-row class="reason_title" v-if="!modalReasons.isOutlet">{{ $t('route.Warranty') }}</a-row> -->
          <a-row class="reason_title">{{ $t('route.Warranty') }}</a-row>
          <a-row
            v-for="element in modalReasons.reasons"
            :key="element.ID2"
            :label="element.Name"
          >
            <label class="PillList-item" v-if="element.in_warranty && element.ID != 17">
              <input
                type="checkbox"
                name="feature"
                :checked="element.checked"
                :disabled="element.is_disabled || element.is_truely_disabled"
                @click="ReasonCheckbox(element, element.ID, modalReasons)"
              >
              <span :class="element.is_disabled ? 'PillList-label disable' : 'PillList-label'">{{ $t("reasonsName." + covertToCamelCase(element.Name)) }}
                <span class="Icon Icon--checkLight Icon--smallest"><CheckOutlined /></span>
              </span>
            </label>
          </a-row>
        </a-col>
        <a-col :span="7" v-if="modalReasons.isGoldRing" v-show="!modalReasons.renewFlag">
          <a-row class="reason_title">{{ $t('route.other') }}</a-row>
          <a-row
            v-for="element in modalReasons.reasons"
            :key="element.ID2"
            :label="element.Name"
          >
            <label class="PillList-item" v-if="element.ID == 17">
              <input
                type="checkbox"
                name="feature"
                :checked="element.checked"
                :disabled="element.is_disabled || element.is_truely_disabled"
                @click="ReasonCheckbox(element, element.ID, modalReasons)"
              >
              <span :class="element.is_disabled ? 'PillList-label disable' : 'PillList-label'">{{ $t("reasonsName." + covertToCamelCase(element.Name)) }}
                <span class="Icon Icon--checkLight Icon--smallest"><CheckOutlined /></span>
              </span>
            </label>
          </a-row>
        </a-col>
        <a-col :span="16" v-show="modalReasons.renewFlag">
          <a-row class="renew_details">{{ $t('route.RenewDetails') }}</a-row>
          <div class="renew_details_box">
            <a-row
              v-for="element in modalReasons.reasons"
              :key="element.ID2"
              :label="element.Name"
            >
              <label class="PillList-item" v-if="element.in_warranty">
                <input
                  type="checkbox"
                  name="feature"
                  :checked="element.checked"
                  @click="ReasonCheckbox(element, element.ID, modalReasons)"
                >
                <span :class="element.is_disabled ? 'PillList-label disable' : 'PillList-label'">{{ $t("reasonsName.Renew") }}: {{ $t("reasonsName." + covertToCamelCase(element.Name)) }}
                  <span class="Icon Icon--checkLight Icon--smallest"><CheckOutlined /></span>
                </span>
              </label>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner"
    >
      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        >
        </StreamBarcodeReader>
      </div>
    </a-modal>
  </div>
</template>

<script>
  // import imageCompression from 'browser-image-compression';
  import { unref, reactive, ref, toRaw, computed } from '@vue/reactivity'
  import { onMounted, watch } from 'vue'
  import { notification, Modal } from 'ant-design-vue';
  import { formatDate, calcIsWarranty, compressImage } from '@/utils/utils';
  import stepform from "@/composables/stepform"
  import useRmaRepositories from '@/composables/useRmaRepositories'
  import { useI18n } from 'vue-i18n';
  import { CheckOutlined, ScanOutlined } from '@ant-design/icons-vue'
  import CountryCodeJson from '@/utils/country_code'
  import { find, includes } from 'lodash'
  import provinceOptions from '@/utils/province';
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import { getWsProductIsColorFading, getWsProductIsGold, getWsProductIsGoldRing, handleProductReason } from '@/utils/getWsProducts';

  export default {
    components: {
      CheckOutlined,
      ScanOutlined,
      StreamBarcodeReader
    },
    props: {
      results: { type: Object, default: () => ({}) },
    },
    emits: ['nextStep'],
    data() {
      return {
        files: []
      };
    },
    setup(props, context) {
      const { rmaCreationState } = stepform()
      const { rma_reasons, getRmaReasons, createRma, getRmaRepairFees, getWsProduct, getRmaCountry, getRmaLanguage, deliverTypeFn, getProductCert, getBoutiqueCountryList, getShippingFeeList, getVerifyInvoice } = useRmaRepositories()
      const modalReasons = ref([])
      const loadingCreate = ref(false)
      const isClickCreate = ref(false)
      const isChinaShop = ref(false)
      const AmountList = ref([])
      const currency = ref('')
      const isBlankRMA = (typeof props.results === 'undefined' || props.results === null)
      const formRef = ref();
      let rmaProducts = ref([])
      const isSearch = ref(true)
      const reasons = ref([])
      const CountryCodeData = ref(CountryCodeJson)
      const countryList = ref([])
      const languageList = ref([])
      const provinceOptionsArr = ref(provinceOptions)
      const isShowDeliveryInfo = ref(false)
      const isShowToCustomer = ref(false)
      const isMustSendToCustomer = ref(false)
      const isShowReceiver = ref(false)
      const isRequiredProvince = ref(true)
      const showScanner = ref(false)
      const BarcodeReader = ref()
      const selectedScanIndex = ref(-1)
      const isOverSeaBoutique = ref(false)
      const boutiqueCountryList = ref([])
      const shippingFeeList = ref([])
      const checkedInvoice = ref('')

      const { t ,locale } = useI18n({ useScope: 'global' })

      onMounted(async () => {
        await getRmaReasons()
        await getMainteanceAmountList()
        if (isBlankRMA) {
          await getCountryList()
          await getLanguageList()
        }
        // console.log('onMounted StepCreateRMA')
        // console.log(props.results["customerInfo"], `props.results["customerInfo"]`)
        if (typeof props.results !== 'undefined' && props.results !== null) {
          isSearch.value = false
          formState.email = props.results["customerInfo"].data["emailaddress1"] || ''
          originalSt.email = props.results["customerInfo"].data["emailaddress1"]
          formState.firstname = props.results["customerInfo"].data["firstname"] || ''
          originalSt.firstname = props.results["customerInfo"].data["firstname"]
          formState.lastname = props.results["customerInfo"].data["lastname"] || ''
          originalSt.lastname = props.results["customerInfo"].data["lastname"]
          formState.phone_code = props.results["customerInfo"].data["new_apm_phoneareacode"]
          formState.crm_customer_id = props.results["customerInfo"].data["crm_customer_id"]
          if (formState.phone_code && formState.phone_code.length > 0) {
            formState.phone_code = formState.phone_code.replace("+","")
          }
          formState.phone = props.results["customerInfo"].data["mobilephone"]
          originalSt.phone = props.results["customerInfo"].data["phone"]

          const isShop = localStorage.getItem("usertype") === "shop"
          const isCHN = localStorage.getItem("country_code") === "CHN"

          if (isShop && isCHN) {
            isChinaShop.value = true
            formState.phone_code = 86
          }

          // console.log(props.results["customerInfo"].data["mobilephone"])
          // if (props.results["customerInfo"].data["mobilephone"] !== "" || props.results["customerInfo"].data["mobilephone"] !== null) {
          //   formState.phone = props.results["customerInfo"].data["mobilephone"].replace('+','');
          // }

          formState.underWarranty = false
          for (let i =0; i < props.results["product"].length; i++ ) {
            if (props.results["product"][i].selected) {
              let prod = {
                key: 0,
                name: "",
                size: "",
                avatar: "",
                warranty: false,
                uploadImage: [],
                uploadGoldWeightImage: [],
                reasons: [],
                origin_invoice_number: "",
                dateOfPurchase: "",
                repair_fee: "",
                amountList: [],
                waived_fee_status: false,
                showWaivedFee: false,
                comments: "",
                invoice_number: "",
                isOutlet: false,
                country: "",
                city: "",
                fulfilledDate: "",
                type: "",
                language: "",
                renewFlag: false,
                sequenceNo: '',
                certInfo: {},
                TotalWeight: '',
                checkedInvoiceIsTrue: false
              };
              prod.dateOfPurchase = formatDate(props.results["product"][i].createdon)
              prod.origin_invoice_number = props.results["product"][i].thk_doc_no
              prod.thk_country = props.results["product"][i].thk_country
              prod.thk_type = props.results["product"][i].thk_type
              if (props.results["product"][i].repair_fee) {
                prod.repair_fee = props.results["product"][i].repair_fee + ' ' + props.results["product"][i].repair_fee_ccy
              }
              if(typeof props.results["product"][i].apm_invoice_no !== 'undefined' && props.results["product"][i].apm_invoice_no != "") {
                prod.order_number = props.results["product"][i].apm_invoice_no
              } else {
                prod.order_number = props.results["product"][i].order_id
              }

              prod.invoice_number = props.results["product"][i].invoice_number || ''
              prod.name = props.results["product"][i].productname
              prod.size = props.results["product"][i].thk_variant_code.thk_code
              const WsProduct = await getWsProduct(prod.name, prod.size)
              prod.isGold = getWsProductIsGold(WsProduct)
              prod.isGoldRing = getWsProductIsGoldRing(WsProduct)
              prod.avatar = "https://imgcdn.apm-monaco.cn/products-images/" + props.results["product"][i].productname + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
              if (props.results["product"][i].warranty === "Under Warranty" || props.results["product"][i].warranty === "Yes") {
                prod.warranty = true
              }
              prod.key = rmaProducts.value.length + 1
              prod.isOutlet = props.results["product"][i].new_apm_outlets == 1
              // Object.assign(prod.reasons, rma_reasons.value.data)
              prod.date_less_twelve = DateLessthan12Month(prod.dateOfPurchase)
              // 按产品返回不同reason list
              prod.reasons = handleProductReasons(prod, WsProduct, false)
              // (!prod.warranty || prod.isOutlet)
              // if (!prod.warranty) {
              //   prod.showWaivedFee = true
              //   prod.repair_fee = AmountList.value[1].value
              // } else {
              //   prod.repair_fee = AmountList.value[0].value
              // }
              prod.isInit = true
              rmaProducts.value.push(prod)
            }
            formState.origin_invoice_number = props.results["product"][i].thk_doc_no;
            formState.order_number = props.results["product"][i].order_id;
            originalSt.origin_invoice_number = props.results["product"][i].thk_doc_no;
            originalSt.order_number = props.results["product"][i].order_id;
          }
        } else {
          const isShop = localStorage.getItem("usertype") === "shop"
          const country_code = localStorage.getItem("country_code")
          if (isShop) formState.phone_code = find(CountryCodeData.value, function(v) { return v.ISO_3 === country_code}).phone_code
          CountryCodeData.value.forEach(v => {
            v.label = `${v.ISO_3} (+${v.phone_code})`
          })
        }
        getBoutiqueCountries();
        getShippingFee();
        getDeliverType();
      })

      const handleProductReasons = (prod, WsProduct, isBlankRMAReason) => {
        const isColorFadingInWarranty = getWsProductIsColorFading(WsProduct)
        // isBlankRMAReason判断是否空白RMA创建，为false时判断warranty，为true则不判断
        const is_warranty = isBlankRMAReason ? true : !prod.warranty
        const reasons = []
        for(let j = 0; j < rma_reasons.value.data.length; j++) {
          let reason_data = { ID: 0, ID2: 0, Name: "", Description: "", checked: false }
          reason_data.ID = rma_reasons.value.data[j].ID
          reason_data.ID2 = `key_${prod.key}_${reason_data.ID}`
          reason_data.Name = rma_reasons.value.data[j].Name
          reason_data.Description = rma_reasons.value.data[j].Description
          // reason_data.checked = (rma_reasons.value.data[j].ID == 13 && is_warranty) ? true : false
          reason_data.in_warranty = rma_reasons.value.data[j].in_warranty == 1
          reason_data.is_disabled = rma_reasons.value.data[j].ID != 13 && is_warranty
          reason_data.is_truely_disabled = (reason_data.Name == 'Color Fading Under Warranty' && !(prod.date_less_twelve && isColorFadingInWarranty)) || (rma_reasons.value.data[j].ID != 13 && is_warranty)
          // 按product info判断是否包含reason
          if (handleProductReason(WsProduct, reason_data)) reasons.push(reason_data)
          // outlet逻辑，isBlankRMAReason为true时无需执行，后续若需使用需要补全isBlankRMAReason判断逻辑
          // Outlets RMA logic code Recover
          // if (!(reason_data.ID == 1 && isWhite) && !(prod.isOutlet && reason_data.Name == 'Color Fading Under Warranty')) {
          //   reasons.push(reason_data)
          // }
        }
        return reasons
      }

      const handleChecked = (val) => {
        formState.wh_ship_to_customer = isShowDeliveryInfo.value ? 1 : 0;
        formState.customer_country_code = ''
        formState.customer_country_or_reigon = ''
        if (val.target.checked) {
          // formState.shipping_fee = AmountList.value[1].price_cost
          // formState.shipping_fee_ccy = AmountList.value[1].currency
          const currentShopShippingFee = shippingFeeList.value.filter(item => item.currency == AmountList.value[0].currency)
          if (currentShopShippingFee.length > 0) {
            formState.shipping_fee = currentShopShippingFee[0].price_cost
            formState.shipping_fee_ccy = currentShopShippingFee[0].currency
          } else {
            formState.shipping_fee = shippingFeeList.value.filter(item => item.currency == 'EUR')[0].price_cost
            formState.shipping_fee_ccy = shippingFeeList.value.filter(item => item.currency == 'EUR')[0].currency
          }
          rmaProducts.value.forEach(item => {
            if (!item.waived_fee_status) {
              item.checkedInvoiceIsTrue = false
              item.invoice_number = ''
            }
          })
        } else {
          formState.customer_province = ''
          formState.customer_city = ''
          formState.customer_area = ''
          formState.customer_address = ''
          formState.customer_postal_code = ''
          formState.receiver_name = ''
          formState.receiver_phone = ''
          formState.province_value = []
          formState.shipping_fee = ''
          formState.shipping_fee_ccy = ''
        }
      }

      const handleCascader = (res) => {
        formState.customer_province = res[0];
        formState.customer_city = res[1] ? res[1] : '';
        formState.customer_area = res[2] ? res[2] : '';
      }

      const getDeliverType = async () => {
        let res = await deliverTypeFn({})
        if (res.success) {
          isShowToCustomer.value = (res.data == 0) ? false : true;
          isMustSendToCustomer.value = (res.data == 1 || res.data == 3);
          isShowDeliveryInfo.value = (res.data == 1 || res.data == 3);
          isRequiredProvince.value = (res.data != 3);
          isOverSeaBoutique.value = (res.data == 4);
          formState.wh_ship_to_customer = (res.data == 1 || res.data == 3) ? 1 : 0;
        } else {
          console.log('errMessage======>', res.message);
        }
      }

      const getMainteanceAmountList = async () => {
        const result = await getRmaRepairFees()
        const list = []
        result.data.forEach(v => {
          list.push({
            value: v.price_cost + ' ' + v.currency,
            label: v.price_cost + ' ' + v.currency,
            price_cost: v.price_cost,
            currency: v.currency
          })
        })
        AmountList.value = list
        currency.value = result.data[0].currency
      }

      const getCountryList = async () => {
        const result = await getRmaCountry()
        const list = []
        result.data.forEach(v => {
          list.push({
            value: v.new_name,
            label: v.new_name
          })
        })
        countryList.value = list
      }

      const getLanguageList = async () => {
        const result = await getRmaLanguage()
        const list = []
        result.data.forEach(v => {
          list.push({
            value: v.new_code,
            label: v.new_name
          })
        })
        languageList.value = list
      }

      const showModalReasons = (item) => {
        modalReasons.value = item
        showReasonsModal.value = true
      }

      const ChangeExpiryDate = (n_tem) => {
        let rma_prod = rmaProducts.value.filter(item => item.key === n_tem.key);

        rmaProducts.value.filter(item => item.key === n_tem.key).dateOfPurchase = n_tem.dateOfPurchase;
        // rmaProducts.value.filter(item => item.key === n_tem.key).dateOfPurchase = n_tem.dateOfPurchase;

        rmaProducts.value.filter(item => item.key === n_tem.key).warranty = calcIsWarranty(n_tem.dateOfPurchase)
        n_tem.warranty = calcIsWarranty(n_tem.dateOfPurchase)
        if (!n_tem.warranty) {
          n_tem.showWaivedFee = true
          n_tem.repair_fee = AmountList.value[1].value
          n_tem.reasons.forEach(v => {
            v.is_disabled = true
            v.checked = false
            if (v.ID == 13) {
              v.checked = true
            }
          })
          return n_tem.isInit = true
        } else {
          n_tem.showWaivedFee = false
          n_tem.waived_fee_status = false
          const checkedList = n_tem.reasons.filter(item => {
            return item.checked == true
          })
          if (!n_tem.isInit && checkedList.length > 0) return
          n_tem.repair_fee = ''
          n_tem.reasons.forEach(v => {
            v.is_disabled = false
            v.checked = false
          })
        }
        n_tem.isInit = false

        // let date2 = new Date(n_tem.dateOfPurchase)
        // let date1 = new Date();
        // let Difference_In_Time = date1.getTime() - date2.getTime();
        // let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        // if (Difference_In_Days <= 712) {
        //   rmaProducts.value.filter(item => item.key === n_tem.key).warranty = true
        //   n_tem.warranty = true
        // } else {
        //   rmaProducts.value.filter(item => item.key === n_tem.key).warranty = false
        //   n_tem.warranty = false
        // }
        // calcAmountList(n_tem)
      }

      const onDeleteProduct = key => {
        rmaProducts.value = rmaProducts.value.filter(item => item.key !== key);
      }

      const validatePhone = async (rule, value, callback) => {
        if (formState.phone_code == '' || formState.phone_code == null) {
          return Promise.reject(`${t("createRMA.PleaseInputThePhoneCode")}${!isSearch.value ? `, ${t("createRMA.ModifyInCRM")}` : ''}`);
        } else if (value == '') {
          // return Promise.reject('Please input the phone and phone code');
          return Promise.reject(t("createRMA.PleaseInputThePhone"));
        } else {
          return Promise.resolve();
        }
        // else if (!/^\d+$/.test(value) || !/^\+?\d+$/.test(formState.phone_code)) {
        //   return Promise.reject(`${t("createRMA.PleaseInputOnlyNumbers")}${!isSearch.value ? `, ${t("createRMA.ModifyInCRM")}` : ''}`);
        // }
      };
      const rules = computed(() => {
        return {
          firstname: [
            {
              required: isSearch.value,
              message: t('createRMA.PleaseInputFirstName'),
              trigger: 'blur',
            },
          ],
          lastname: [
            {
              required: isSearch.value,
              message: t('createRMA.PleaseInputLastName'),
              trigger: 'blur',
            },
          ],
          /* origin_invoice_number: [
            {
              required: true,
              message: 'Please insert Invoice Number',
              trigger: 'blur',
            },
          ],
          order_number: [
            {
              required: true,
              message: 'Please insert Order Number',
              trigger: 'blur',
            },
          ],
          dateOfPurchase: [
            {
              required: true,
              message: 'Please insert Date of Purchase',
              trigger: 'blur',
            },
          ], */
          email: [
            {
              // pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/,
              pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
              required: isSearch.value,
              message: t('createRMA.PleaseInputEmail'),
              trigger: 'blur',
            },
          ],
          phone:[{ required: isChinaShop.value || isSearch.value, validator: validatePhone, trigger: 'blur' }],
          comments: [
            {
              message: t('route.TextLengthIsTooLong'),
              trigger: 'change',
              max: 120
            }
          ],
          province_value: [
            {
              required: isRequiredProvince.value && !isOverSeaBoutique.value,
              message: t('createRMA.PleaseSelectProvince'),
              trigger: 'change',
              type: 'array',
            },
          ],
          customer_country_code: [
            {
              required: isOverSeaBoutique.value,
              message: t('createRMA.PleaseSelectCountry'),
              trigger: 'change'
            },
          ],
          customer_address: [
            {
              required: true,
              message: t('createRMA.PleaseInputAddress'),
              trigger: 'blur',
            },
          ],
          receiver_name: [
            {
              required: true,
              message: t('createRMA.PleaseInputReceiverName'),
              trigger: 'blur',
            },
          ],
          receiver_phone: [
            {
              required: true,
              message: t('createRMA.PleaseInputReceiverPhone'),
              trigger: 'blur',
            },
            // { pattern: /^1[3,4,5,6,7,8,9][0-9]{9}$/, message: t('createRMA.IncorrectPhone'), trigger: 'blur', }
          ],
        };
      })

      const createRma2 = () => {
        formRef.value.validate()
          .then(() => {
            loadingCreate.value = true
            isClickCreate.value = true
            // const isValid = ValidateProductform();
            // if(isValid.length <= 0){
            //   throw 'Product Reasons are mandatory\r\n. Please ensure all product have a seleted reason';
            // }
            const isCheckGoldProductSequenceNo = ValidateGoldProductSequenceNo()
            if (!isCheckGoldProductSequenceNo) {
              throw t("createRMA.GoldProductInfoAreMandatory");
            }
            const isTotalWeight = ValidateTotalWeight()
            if (!isTotalWeight) {
              throw t("createRMA.TotalWeightAreMandatory");
            }

            const isOriginalOrderIDValid = ValidateOriginalOrderID()
            if (!isOriginalOrderIDValid) {
              throw t("createRMA.OriginalOrderIdAreMandatory");
            }

            const isOrderNumberValid = ValidateOrderNumber()
            if (!isOrderNumberValid) {
              throw t("createRMA.OriginalNumberAreMandatory");
            }

            const isDateValid = ValidateDateform();
            if (!isDateValid) {
              throw t("createRMA.ProductDateAreMandatory");
            }

            const isValid = ValidateProductform();
            if (!isValid) {
              throw t("createRMA.ProductReasonsAreMandatory")+'\r\n.'+t("createRMA.PleaseEnsureAllProductHaveASelectedReason");
            }

            const isMaintenanceAmount = ValidateMaintenanceAmount();
            if (!isMaintenanceAmount) {
              throw t("createRMA.ProductMaintenanceAmountAreMandatory");
            }

            const isInvoiceNumber = ValidateInvoiceNumber();
            if (!isInvoiceNumber) {
              throw t("createRMA.ProductInvoiceNumberAreMandatory");
            }

            const isWaivedFeeAndComments = ValidateWaivedFeeAndComments();
            if (!isWaivedFeeAndComments) {
              throw t("createRMA.ProductWaivedFeeAndCommentsAreMandatory");
            }

            const isBlankOrderRequired = ValidateBlankOrderRequired();
            if (isBlankRMA && isBlankOrderRequired != '') {
              throw isBlankOrderRequired;
            }

            const isCheckInvoiceWhenShipToCustomer = ValidateCheckInvoiceWhenShipToCustomer();
            if (!isCheckInvoiceWhenShipToCustomer) {
              throw t("createRMA.pleaseCheckInvoiceAgain");
            }

            // console.log(rmaProducts.value)
            // console.log('all good')
            rmaCreationState.value = []
            let promiseArray = []
            for (let i =0; i < rmaProducts.value.length; i++) {
              formState.rma_item_to_reason = []
              if (rmaProducts.value[i].reasons.length > 0) {
                for (let j = 0; j < rmaProducts.value[i].reasons.length; j++) {
                  if (rmaProducts.value[i].reasons[j].checked) {
                    let rma_item_reason = {rma_item_reason_id: rmaProducts.value[i].reasons[j].ID};
                    formState.rma_item_to_reason.push(rma_item_reason)
                  }
                }
                const formData = new FormData();
                if(originalSt.email === formState.email &&
                  originalSt.firstname === formState.firstname &&
                  originalSt.lastname === formState.lastname &&
                  originalSt.phone === formState.phone)
                {
                  formData.append("is_same_customer_as_crm", 1)
                }

                formData.append("product_sku", rmaProducts.value[i].name)
                formData.append("product_size", rmaProducts.value[i].size)

                for (const j of Object.keys(rmaProducts.value[i].uploadImage)) {
                  formData.append('attachment', rmaProducts.value[i].uploadImage[j])
                }

                const isImageExist = rmaProducts.value[i].uploadImage.length
                if (!isImageExist) {
                  throw t("route.imageMandatory");
                }

                if (rmaProducts.value[i].isGold) {
                  const isGoldWeightImageExist = rmaProducts.value[i].uploadGoldWeightImage.length
                  if (!isGoldWeightImageExist) {
                    throw t("route.goldWeightImageMandatory");
                  }
                  for (const j of Object.keys(rmaProducts.value[i].uploadGoldWeightImage)) {
                    formData.append('gold_goods_attachment', rmaProducts.value[i].uploadGoldWeightImage[j])
                  }
                  formData.append("rma_gold_goods_info", JSON.stringify({
                    cert_no: rmaProducts.value[i].certInfo.SmallBagNo,
                    gold_weight: rmaProducts.value[i].certInfo.GoldWeight,
                    gold_weight_before_repaired: rmaProducts.value[i].TotalWeight
                  }))
                }

                if (isBlankRMA) {
                  formData.append("blankRma", 1)
                  formData.append("country", rmaProducts.value[i].country)
                  formData.append("city", rmaProducts.value[i].city)
                  formData.append("channel_type", rmaProducts.value[i].type)
                  formData.append("completion_time", rmaProducts.value[i].fulfilledDate)
                  formData.append("preferred_language", rmaProducts.value[i].language)
                } else {
                  formData.append("blankRma", 0)
                  formData.append("country", rmaProducts.value[i].thk_country)
                  formData.append("channel_type", rmaProducts.value[i].thk_type)
                }

                if (rmaProducts.value[i].origin_invoice_number !== undefined) {
                  formData.append("origin_invoice_number", rmaProducts.value[i].origin_invoice_number)
                } else {
                  formData.append("origin_invoice_number", "")
                }
                formData.append("retail_carrier_code", "")
                formData.append("date_of_purchase", rmaProducts.value[i].dateOfPurchase)
                formData.append("order_number", rmaProducts.value[i].order_number)
                formData.append("scomments", rmaProducts.value[i].comments)
                formData.append("repair_fee", rmaProducts.value[i].repair_fee.split(' ')[0])
                formData.append("repair_fee_ccy", rmaProducts.value[i].repair_fee.split(' ')[1])
                formData.append("waived_fee_status", rmaProducts.value[i].waived_fee_status ? 1 : 0)
                formData.append("invoice_number", rmaProducts.value[i].invoice_number)
                formData.append("is_outlets", rmaProducts.value[i].isOutlet ? 1 : 0)

                formData.append("firstname", formState.firstname)
                formData.append("created_by", localStorage.getItem("last_name"))
                formData.append("lastname", formState.lastname)
                formData.append("email", formState.email)
                formData.append("phone_code", formState.phone_code)
                formData.append("phone", formState.phone)
                formData.append("store_id", formState.store_id)
                // formState.underWarranty = calcIsWarranty(rmaProducts.value[i].dateOfPurchase)
                // formData.append("is_under_warranty", formState.underWarranty)
                formData.append("is_under_warranty", rmaProducts.value[i].warranty)
                formData.append("rma_item_return_reason", JSON.stringify(formState.rma_item_to_reason))
                formData.append("crm_customer_id", formState.crm_customer_id)

                // 新增的省市区，邮政编号，详细地址
                formData.append("wh_ship_to_customer", formState.wh_ship_to_customer)
                formData.append("customer_country_or_reigon", formState.customer_country_or_reigon)
                formData.append("customer_country_code", formState.customer_country_code)
                formData.append("shipping_fee_ccy", isOverSeaBoutique.value ? formState.shipping_fee_ccy : '')
                formData.append("shipping_fee", isOverSeaBoutique.value ? formState.shipping_fee : '')
                formData.append("customer_province", formState.customer_province)
                formData.append("customer_city", formState.customer_city)
                formData.append("customer_area", formState.customer_area)
                formData.append("customer_address", formState.customer_address)
                formData.append("customer_postal_code", formState.customer_postal_code)
                formData.append("receiver_name", (isShowReceiver.value ? formState.receiver_name : (formState.firstname + formState.lastname)))
                formData.append("receiver_phone", (isShowReceiver.value ? formState.receiver_phone.replace(/^\s|\s$/g, '') : formState.phone))

                try {
                  promiseArray.push(createRma(formData))
                } catch (e) {
                  console.log(e);
                  loadingCreate.value = false
                  isClickCreate.value = false
                }
              } else {
                alert('Cannot create')
                break;
              }
            }
            Promise.all(promiseArray).then((data) => {
              loadingCreate.value = false
              isClickCreate.value = false
              let success = true
              data.forEach(v => {
                if (!v.success) {
                  success = false
                  notification.error({
                    message: t('route.error'),
                    description: v.message + ' By ' + v.input.product_sku
                  })
                } else {
                  rmaProducts.value.forEach((item, i) => {
                    if (item.order_number == v.input.order_number && item.name == v.input.product_sku && item.size == v.input.product_size) {
                      rmaProducts.value.splice(i, 1)
                    }
                  })
                }
              })
              if (success) {
                rmaCreationState.value = data
                context.emit('nextStep', 2)
              }
            })
          })
          .catch(error => {
            let message = '';
            if (typeof error === 'object' && error.errorFields && error.errorFields.length > 0) {
              message += error.errorFields.map(errors => errors.errors[0]+"\n");
            } else {
              message =error;
            }
            notification.error({
              message: t('route.error'),
              description: message
            })
            loadingCreate.value = false
            isClickCreate.value = false
          });
      }

      const formState = reactive({
        firstname: '',
        lastname: '',
        email: '',
        phone_code: '',
        phone: '',
        origin_invoice_number: '',
        retail_carrier_code: '',
        is_same_customer_as_crm: false,
        dateOfPurchase: '',
        store_id: '',
        underWarranty: false,
        rma_item_to_reason: [],
        reasons: [],
        product_sku: '',
        product_size: '',
        crm_order_guid: '',
        crm_item_guid: '',
        upload_attachment: [],
        comments: '',
        order_number: '',
        crm_customer_id: '',
        wh_ship_to_customer: 0,
        customer_province: '',
        customer_city: '',
        customer_area: '',
        customer_address: '',
        customer_postal_code: '',
        receiver_name: '',
        receiver_phone: '',
        province_value:[],
        customer_country_code: '',
        customer_country_or_reigon: '',
        shipping_fee: '',
        shipping_fee_ccy: ''
      })
      const originalSt = reactive({
        firstname: '',
        lastname: '',
        emaill: '',
        phone: '',
        origin_invoice_number: '',
        order_number: ''
      })
      const modalAddProduct = ref(false)
      const newProduct = ref({
        key: 0,
        name: "",
        size: "",
        avatar: "",
        warranty: false,
        uploadImage: [],
        uploadGoldWeightImage: [],
        reasons: [],
        repair_fee: "",
        amountList: [],
        waived_fee_status: false,
        showWaivedFee: false,
        comments: "",
        invoice_number: "",
        isOutlet: false,
        country: "",
        city: "",
        fulfilledDate: "",
        type: "",
        language: "",
        renewFlag: false,
        sequenceNo: '',
        certInfo: {},
        TotalWeight: '',
        checkedInvoiceIsTrue: false
      })
      const options = ref([])
      const search = ref()
      const modalProductSize = ref([])
      const showReasonsModal = ref(false)

      const EnableModalAddProduct = () => {
        modalAddProduct.value = true
        newProduct.value = {
          key: 0,
          name: "",
          size: "",
          avatar: "",
          warranty: false,
          uploadImage: [],
          uploadGoldWeightImage: [],
          reasons: [],
          repair_fee: "",
          amountList: AmountList.value,
          waived_fee_status: false,
          showWaivedFee: false,
          comments: "",
          invoice_number: "",
          isOutlet: false,
          country: "",
          city: "",
          fulfilledDate: "",
          type: "",
          language: "",
          renewFlag: false,
          sequenceNo: '',
          certInfo: {},
          TotalWeight: '',
          checkedInvoiceIsTrue: false
        }
      }

      const searchClient = new MeiliSearch({
        host: process.env.VUE_APP_MEILI_API_URL,
        apiKey: process.env.VUE_APP_MEILI_API_KEY,
      })
      const index = searchClient.index('productrma')

      const mockVal = (str, repeat = 1) => {
        return {
          value: str.repeat(repeat),
        };
      };

      const typeSenseSearch = async (searchText)=> {
        let requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        try {
          const type_sense_api = process.env.VUE_APP_TYPESENSE_API_URL
          const response = await fetch(type_sense_api+"/searchproduct/"+searchText, requestOptions)
          const result = await response.json()
          return result
        } catch (error) {
          console.log('error', error)
        }
      }

      const onSearch = async searchText => {
        search.value = await typeSenseSearch(searchText)
        // options.value = !searchText
        //   ? []
        //   : [mockVal(search.value.hits[0].document.pid), mockVal(search.value.hits[1].document.pid), mockVal(search.value.hits[2].document.pid), mockVal(search.value.hits[3].document.pid)];
        // console.log("Total return:", search.value);
        if (search.value.hits.length >= 1) {
          options.value = []
          // options.value = !searchText? [] : [mockVal(search.value.hits[0].document.product_id), mockVal(search.value.hits[1].document.product_id), mockVal(search.value.hits[2].document.product_id), mockVal(search.value.hits[3].document.product_id)];
          if (!searchText) return options.value = []
          for (let i = 0; i < search.value.hits.length; i++) {
            const v = search.value.hits
            if (i == 0 || (i > 0 && v[i].document.product_id != v[i - 1].document.product_id)) {
              options.value.push(mockVal(v[i].document.product_id))
            }
          }
        } else {
          options.value = []
        }
      };

      const onSelect = value => {
        const found = search.value.hits.filter(element => {
          return element.document.product_id === value
        })

        modalProductSize.value = []
        newProduct.value.size = ''
        if (found.length > 0) {
          for (let i = 0; i < found.length; i++) {
            if (found[i].document.size == "NO-SIZE") {
              let prod = { size_code: "NO-SIZE" };
              modalProductSize.value.push(prod)
              break
            } else {
              let prod = { size_code: found[i].document.size };
              modalProductSize.value.push(prod)
            }
          }
        } else {
          let prod = { size_code: "NO-SIZE" }
          modalProductSize.value.push(prod)
        }
      };

      const ValidateProductform = () => {
        let is_CheckedProduct = false
        for (let i = 0; i < rmaProducts.value.length; i++) {
          let checkInProcess = false
          for (let j =0; j < rmaProducts.value[i].reasons.length; j++) {
            if (rmaProducts.value[i].reasons[j].checked) {
              checkInProcess = true
              break
            }
          }
          if (checkInProcess) {
            is_CheckedProduct = true
          } else {
            is_CheckedProduct = false;
            break
          }
        }
        return is_CheckedProduct
      }

      const ValidateDateform = () => {
        let is_CheckedProduct = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          // console.log(rmaProducts.value[i].dateOfPurchase)
          if (rmaProducts.value[i].dateOfPurchase == null || rmaProducts.value[i].dateOfPurchase == 'undefined') {
            is_CheckedProduct = false
            break
          }
          if (rmaProducts.value[i].dateOfPurchase === "") {
            is_CheckedProduct = false
            break
          }
        }
        return is_CheckedProduct
      }

      const ValidateGoldProductSequenceNo  = () => {
        let is_CheckedGoldProductSequenceNo = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          if (rmaProducts.value[i].isGold && !rmaProducts.value[i].certInfo.SmallBagNo) {
            is_CheckedGoldProductSequenceNo = false
            break
          }
        }
        return is_CheckedGoldProductSequenceNo
      }

      const ValidateTotalWeight  = () => {
        let is_TotalWeight = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          if (rmaProducts.value[i].isGold && rmaProducts.value[i].TotalWeight == '') {
            is_TotalWeight = false
            break
          }
        }
        return is_TotalWeight
      }

      const ValidateOriginalOrderID  = () => {
        let is_CheckedOriginalProductId = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          // console.log(rmaProducts.value[i])
          if (rmaProducts.value[i].origin_invoice_number == null || rmaProducts.value[i].origin_invoice_number == 'undefined') {
            is_CheckedOriginalProductId = false
            break
          }
          if (rmaProducts.value[i].origin_invoice_number === "") {
            is_CheckedOriginalProductId = false
            break
          }
        }
        return is_CheckedOriginalProductId
      }

      const ValidateOrderNumber = () => {
        let is_CheckedOriginalProductId = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          if (rmaProducts.value[i].order_number == null || rmaProducts.value[i].order_number == 'undefined') {
            is_CheckedOriginalProductId = false
            break
          }
          if (rmaProducts.value[i].order_number === "") {
            is_CheckedOriginalProductId = false
            break
          }
        }
        return is_CheckedOriginalProductId
      }

      const ValidateMaintenanceAmount = () => {
        let is_CheckedMaintenanceAmount = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          if (rmaProducts.value[i].repair_fee == null || rmaProducts.value[i].repair_fee == 'undefined') {
            is_CheckedMaintenanceAmount = false
            break
          }
          if (rmaProducts.value[i].repair_fee === "") {
            is_CheckedMaintenanceAmount = false
            break
          }
        }
        return is_CheckedMaintenanceAmount
      }

      const ValidateWaivedFeeAndComments = () => {
        let is_CheckedWaivedFeeAndComments = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          if (rmaProducts.value[i].waived_fee_status && rmaProducts.value[i].comments == '') {
            is_CheckedWaivedFeeAndComments = false
            break
          }
        }
        return is_CheckedWaivedFeeAndComments
      }

      const ValidateInvoiceNumber = () => {
        let isInvoiceNumber = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          const item = rmaProducts.value[i]
          if ((!item.warranty || item.showWaivedFee) && !item.waived_fee_status) {
            if (!item.invoice_number || item.invoice_number == '') {
              isInvoiceNumber = false
              break
            }
          }
        }
        return isInvoiceNumber
      }

      const ValidateBlankOrderRequired = () => {
        let errorText = ''
        for (let i = 0; i < rmaProducts.value.length; i++) {
          const item = rmaProducts.value[i]
          if (!item.country) {
            errorText = t('createRMA.CountryIsMandatory')
            break
          }
          if (!item.city) {
            errorText = t('createRMA.CityIsMandatory')
            break
          }
          if (!item.language) {
            errorText = t('createRMA.LanguageIsMandatory')
            break
          }
          if (!item.fulfilledDate) {
            errorText = t('createRMA.FulfilledDateIsMandatory')
            break
          }
          if (!item.type) {
            errorText = t('createRMA.TypeIsMandatory')
            break
          }
        }
        return errorText
      }

      const ValidateCheckInvoiceWhenShipToCustomer = () => {
        let isCheckInvoice = true
        for (let i = 0; i < rmaProducts.value.length; i++) {
          const item = rmaProducts.value[i]
          if (isShowDeliveryInfo.value && !item.checkedInvoiceIsTrue && isOverSeaBoutique.value) {
            isCheckInvoice = false
            break
          }
        }
        return isCheckInvoice
      }

      const covertToCamelCase = (str) => {
        return str.replace(/\s/g, "")
      }

      const chooseFileName = () => {
        return t('route.ChooseFiles')
      }

      const ReasonCheckbox = (reas_item, ID, list_item) => {
        // ID2: 4, 5, 12
        reas_item.checked = !reas_item.checked
        if (ID == 13) {
          list_item.renewFlag = !list_item.renewFlag && reas_item.checked
        }
        // if (!list_item.warranty) return
        // calcAmountList(list_item)
        calcReasons(reas_item.checked, ID, list_item)

        // Outlets RMA logic code Recover
        // if (list_item.isOutlet) {
        //   outletReason(reas_item.checked, ID, list_item)
        // } else {
        //   // calcAmountList(list_item)
        //   calcReasons(reas_item.checked, ID, list_item)
        // }
      }

      const outletReason = (checked, ID, list_item) => {
        const checkedList = list_item.reasons.filter(item => {
          return item.checked == true
        })
        list_item.reasons.forEach((v, i) => {
          if (checked) {
            v.is_disabled = true
            if (returnCalcReasonsSum(list_item)) v.is_disabled = false
            checkedList.forEach(item => {
              if (v.ID == item.ID) v.is_disabled = false
            })
          } else {
            v.is_disabled = false
          }
        })
      }

      const calcAmountList = (list_item) => {
        const checkedList = []
        list_item.repair_fee = ''
        list_item.reasons.forEach((v, i) => {
          if (v.checked) {
            checkedList.push(v.ID)
          }
        })
        try {
          if (checkedList.length == 0) throw false
          checkedList.forEach(v => {
            if (v != 4 && v != 5 && v != 12) {
              throw false
            }
          })
          throw true
        } catch (result) {
          list_item.amountList = result ? AmountList.value : !list_item.warranty ? AmountList.value : [{value: 0 + ' ' + currency.value, label: 0 + ' ' + currency.value}]
        }
        // const reg = /(?<!4|5|12|13|14|15)(\,|$)|\,(?!4|5|12|13|14|15)/g
      }

      const calcReasons = (checked, ID, list_item) => {
        // 4, 5, 12, 13, 14, 15
        // list_item.showWaivedFee = false
        // 若选择寄回客人，地区选择后校验完发票号，已选减免费用，则选择原因时不重置减免费用checkbox
        if (!(list_item.checkedInvoiceIsTrue && list_item.waived_fee_status)) {
          list_item.waived_fee_status = false
        }
        const checkedList = list_item.reasons.filter(item => {
          return item.checked == true
        })
        if (ID == 13 && checked) {
          // 当选中renew时，禁用保修原因，收取repair_fee
          list_item.reasons.forEach(v => {
            v.is_disabled = true
            if (v.ID == ID) v.is_disabled = false
            checkedList.forEach(item => {
              // 已选中原因解除禁用
              if (v.ID == item.ID) v.is_disabled = false
            })
          })
          list_item.repair_fee = AmountList.value[1].value
          list_item.showWaivedFee = true
        } else if (ID == 13 && !checked) {
          // 当取消选中renew时，解除禁用保修原因，repair_fee为空
          list_item.reasons.forEach(v => {
            v.is_disabled = false
            v.checked = false
          })
          list_item.repair_fee = ''
          list_item.showWaivedFee = false
        } else if (returnIsIncludeRenew(list_item)) {
          // 当选中renew时，选择其他具体原因时不处理逻辑
          return
        } else if ((ID == 4 || ID == 5 || ID == 12 || ID == 14 || ID == 15) && checked) {
          // 当选中非renew的不保修原因时，禁用保修原因，收取repair_fee
          list_item.reasons.forEach(v => {
            v.is_disabled = true
            // returnCalcReasonsSum判断已选原因个数，大于等于2个时禁用其他非renew不保修原因
            if (returnCalcReasonsSum(list_item) && (v.ID == 4 || v.ID == 5 || v.ID == 12 || v.ID == 14 || v.ID == 15)) v.is_disabled = false
            if (v.ID == ID) v.is_disabled = false
            checkedList.forEach(item => {
              // 已选中原因解除禁用
              if (v.ID == item.ID) v.is_disabled = false
            })
          })
          list_item.repair_fee = AmountList.value[1].value
          list_item.showWaivedFee = true
        } else if ((ID == 4 || ID == 5 || ID == 12 || ID == 14 || ID == 15) && !checked) {
          // 当取消选中非renew的不保修原因时，解除禁用保修原因，repair_fee为空
          list_item.reasons.forEach(v => {
            // returnCalcReasonsSum判断已选原因个数，少于2个时解除禁用其他非renew不保修原因
            if (returnCalcReasonsSum(list_item) && (v.ID == 4 || v.ID == 5 || v.ID == 12 || v.ID == 14 || v.ID == 15)) v.is_disabled = false
            if (checkedList.length == 0) {
              v.is_disabled = false
              list_item.repair_fee = ''
              list_item.showWaivedFee = false
            }
          })
        } else if ((ID != 4 && ID != 5 && ID != 12 && ID != 13 && ID != 14 && ID != 15) && checked) {
          // 当选中保修原因时，禁用不保修原因，不收取repair_fee
          list_item.reasons.forEach(v => {
            v.is_disabled = true
            // returnCalcReasonsSum判断已选原因个数，大于等于2个时禁用其他保修原因
            if (returnCalcReasonsSum(list_item) && v.ID != 4 && v.ID != 5 && v.ID != 12 && v.ID != 13 && v.ID != 14 && v.ID != 15) v.is_disabled = false
            // const checkedList = list_item.reasons.filter(item => {
            //   return item.checked == true && item.ID != 1
            // })
            checkedList.forEach(item => {
              // 已选中原因解除禁用
              if (v.ID == item.ID) v.is_disabled = false
            })
            // 原因ID == 1以及选中原因本身解除禁用
            if (v.ID == 1 || v.ID == ID) return v.is_disabled = false
          })
          list_item.repair_fee = AmountList.value[0].value
        } else {
          // 当取消选中保修原因时，解除禁用不保修原因，repair_fee为空
          if (checkedList.length > 0) {
            // checkedList已选列表不为空时，不处理repair_fee
            list_item.reasons.forEach(v => {
              if (ID == 1 && !returnCalcReasonsSum(list_item)) {
                // 若取消选中ID == 1，returnCalcReasonsSum判断已选原因个数，大于等于2个时跳过
                return
              } else if (ID == 1 && returnCalcReasonsSum(list_item)) {
                // 若取消选中ID == 1，returnCalcReasonsSum判断已选原因个数，少于2个时解除禁用其他保修原因
                if (v.ID != 4 && v.ID != 5 && v.ID != 12 && v.ID != 13 && v.ID != 14 && v.ID != 15) v.is_disabled = false
              } else if (ID != 1 && !returnCalcReasonsSum(list_item)) {
                // 若取消选中ID != 1，returnCalcReasonsSum判断已选原因个数，大于等于2个时解除禁用其他保修原因
                if (v.ID == ID) v.is_disabled = true
              } else  {
                // 若取消选中ID != 1，returnCalcReasonsSum判断已选原因个数，少于2个时解除禁用其他保修原因
                if (v.ID != 4 && v.ID != 5 && v.ID != 12 && v.ID != 13 && v.ID != 14 && v.ID != 15) v.is_disabled = false
              }
            })
          } else {
            // checkedList已选列表为空时，全体解除禁用，repair_fee为空
            list_item.reasons.forEach(v => {
              v.is_disabled = false
            })
            list_item.repair_fee = ''
          }
        }
      }

      // 保修和不保修原因各自最多可选两个，氧化原因可额外选
      const returnCalcReasonsSum = (list_item) => {
        return list_item.reasons.filter(v => {
          return v.checked == true
        }).length < 2
      }

      const returnIsIncludeRenew = (list_item) => {
        return list_item.reasons.filter(v => {
          return v.ID == 13 && v.checked == true
        }).length > 0
      }

      const confirmWaivedFee = (e, item) => {
        item.waived_fee_status = false
        if (e.target.checked) {
          Modal.confirm({
            title: t("route.confirmWaivedFee"),
            onOk() {
              item.waived_fee_status = true
              item.checkedInvoiceIsTrue = true
            },
            onCancel() {},
          });
        } else {
          item.checkedInvoiceIsTrue = false
          // checkedInvoiceCanCreateRMA()
        }
      }

      const filterOption = (input, option) => {
        return includes(option.label.toLowerCase(), input.toLowerCase());
      };

      const handleAdd = async () => {
        if (newProduct.value.name === '') {
          notification.error({
            message: 'Error',
            description: "Please select a product"
          })
          return
        }
        if (newProduct.value.size === '') {
          notification.error({
            message: 'Error',
            description: "Please select a size"
          })
          return
        }
        newProduct.value.avatar = "https://imgcdn.apm-monaco.cn/products-images/" + newProduct.value.name + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
        const WsProduct = await getWsProduct(newProduct.value.name, newProduct.value.size)
        newProduct.value.isGold = getWsProductIsGold(WsProduct)
        newProduct.value.isGoldRing = getWsProductIsGoldRing(WsProduct)
        newProduct.value.dateOfPurchase = '1982-01-01'
        newProduct.value.repair_fee = AmountList.value[1].value
        newProduct.value.isInit = true
        newProduct.value.key = rmaProducts.value.length + 1
        newProduct.value.date_less_twelve = DateLessthan12Month(newProduct.value.dateOfPurchase)
        // 按产品返回不同reason list
        newProduct.value.reasons = handleProductReasons(newProduct.value, WsProduct, true)
        rmaProducts.value.push(newProduct.value)
        modalAddProduct.value = false
      };

      const DateLessthan12Month = (date) => {
        const order_date = new Date(date)
        const now_date = new Date()
        const y1 = order_date.getFullYear()
        const y2 = now_date.getFullYear()
        const m1 = order_date.getMonth()
        const m2 = now_date.getMonth()
        const d1 = order_date.getDate()
        const d2 = now_date.getDate()
        let distance = (y2 - y1) * 12 + (m2 - m1) + ((d2 - d1) > 0 ? 1 : 0)
        return distance <= 12
      };

      const checkSequenceNo = async (item, index) => {
        const result = await getProductCert(item.sequenceNo)
        if (result.success) {
          item.certInfo = result.data
        } else {
          item.certInfo = {}
          notification.error({
            message: 'Error',
            description: result.message
          })
        }
      }

      const toggleScanner= (index) => {
        showScanner.value = !showScanner.value
        selectedScanIndex.value = index
      }

      const onLoaded = () => {}

      const onDecode = (result) => {
        rmaProducts.value[selectedScanIndex.value].sequenceNo.value = result
        showScanner.value = false
      }

      const onCloseScanner = () => {
        selectedScanIndex.value = -1
      }

      watch(showScanner, (newValues, prevValues) => {
        if (!newValues) BarcodeReader.value.codeReader.stopStreams()
      })

      const saveReasonsData = ref([])
      const saveRepairFee = ref('')
      const saveShowWaivedFee = ref(false)
      const saveIsRenewFlag = ref(false)
      const submitSelectReasons = () => {
        const ReasonLogicIsTrue = ValidateRenewReasons()
        if (!ReasonLogicIsTrue) {
          notification.error({
            message: t('route.error'),
            description: t("createRMA.PleaseSelectAtLeastRenewReason")
          })
        } else {
          saveReasonsData.value = JSON.parse(JSON.stringify(modalReasons.value.reasons));
          saveRepairFee.value = JSON.parse(JSON.stringify(modalReasons.value.repair_fee));
          saveShowWaivedFee.value = modalReasons.value.showWaivedFee;
          saveIsRenewFlag.value = modalReasons.value.renewFlag;
          showReasonsModal.value = false;
        }
      }

      const cancelSelectReasons = () => {
        if (saveReasonsData.value.length > 0) {
          modalReasons.value.reasons = JSON.parse(JSON.stringify(saveReasonsData.value));
          modalReasons.value.repair_fee = JSON.parse(JSON.stringify(saveRepairFee.value));
          modalReasons.value.showWaivedFee = saveShowWaivedFee.value;
          modalReasons.value.renewFlag = saveIsRenewFlag.value;
        } else {
          modalReasons.value.reasons.forEach(v => {
            v.checked = false;
            v.is_disabled = false;
          })
          modalReasons.value.repair_fee = ''
          modalReasons.value.showWaivedFee = false
          modalReasons.value.renewFlag = false
        }
        showReasonsModal.value = false;
      }

      const ValidateRenewReasons = () => {
        let ReasonLogicIsTrue = false
        for (let i = 0; i < modalReasons.value.reasons.length; i++) {
          let checkInProcess = false
          // 当选中Renew原因时需要至少选择一个组合原因，反之则不需要
          if (modalReasons.value.reasons[i].ID == 13 && modalReasons.value.reasons[i].checked) {
            for (let j = 0; j < modalReasons.value.reasons.length; j++) {
              if (modalReasons.value.reasons[j].in_warranty && modalReasons.value.reasons[j].checked) {
                checkInProcess = true
                break
              }
            }
            if (checkInProcess) {
              ReasonLogicIsTrue = true
            } else {
              ReasonLogicIsTrue = false;
              break
            }
          } else if (modalReasons.value.reasons[i].ID == 13 && !modalReasons.value.reasons[i].checked) {
            ReasonLogicIsTrue = true;
            break
          }
        }
        return ReasonLogicIsTrue
      }

      const getBoutiqueCountries = async () => {
        const result = await getBoutiqueCountryList()
        if (result.success) {
          boutiqueCountryList.value = result.data
        } else {
          notification.error({
            message: 'Error',
            description: result.message
          })
        }
      }

      const getShippingFee = async () => {
        const result = await getShippingFeeList()
        if (result.success) {
          shippingFeeList.value = result.data
        } else {
          notification.error({
            message: 'Error',
            description: result.message
          })
        }
      }

      const handleBoutiqueCountry = (val) => {
        const country_item = boutiqueCountryList.value.filter(item => item.iso_code_3 == val)
        // 处理重新选择寄回国家逻辑start
        let checkStatus = true
        rmaProducts.value.forEach(item => {
          if (!item.waived_fee_status) {
            checkStatus = false
            item.checkedInvoiceIsTrue = false
            item.invoice_number = ''
          }
        })
        if (!checkStatus && formState.customer_country_or_reigon != '') {
          notification.info({
            message: t('SAVCheck.infoTitle'),
            description: t('createRMA.pleaseCheckInvoiceAgain')
          })
        }
        // 处理重新选择寄回国家逻辑end
        formState.customer_country_or_reigon = country_item[0].en_name
        const currency_item = shippingFeeList.value.filter(item => item.currency == country_item[0].currency)
        if (currency_item.length > 0) {
          formState.shipping_fee_ccy = currency_item[0].currency
          formState.shipping_fee = currency_item[0].price_cost
        } else {
          formState.shipping_fee_ccy = shippingFeeList.value.filter(item => item.currency == 'EUR')[0].currency
          formState.shipping_fee = shippingFeeList.value.filter(item => item.currency == 'EUR')[0].price_cost
        }
        if (!country_item[0].is_shippable) {
          notification.warning({
            message: t('SAVCheck.warnTitle'),
            description: t('createRMA.isNotAllowSendToCustomer')
          })
        }
      }

      const onCheckInvoice = async (item) => {
        checkedInvoice.value = item.invoice_number
        item.checkedInvoiceLoading = true
        const result = await getVerifyInvoice(item.invoice_number)
        item.checkedInvoiceLoading = false
        if (result.success) {
          // 存在发票数据时
          if (result.data.invoiceNo) {
            // 有shipping信息
            if (result.data.shipping.length > 0) {
              formState.shipping_fee = result.data.shipping[0].price
              formState.shipping_fee_ccy = result.data.transCurrency
              item.checkedInvoiceIsTrue = true
            } else {
              notification.error({
                message: t('route.error'),
                description: t('createRMA.invoiceIsNotIncludeShippingInfo')
              })
            }
          } else {
            notification.error({
              message: t('route.error'),
              description: t('createRMA.invoiceIsNotExit')
            })
          }
        } else {
          notification.error({
            message: t('route.error'),
            description: t('createRMA.checkInvoiceError')
          })
        }
      }

      const checkedInvoiceCanCreateRMA = () => {
        // 数据处理完毕后校验是否能提交RMA
        let checkedInvoiceIsTrueResult = true
        rmaProducts.value.forEach(item => {
          if (!item.checkedInvoiceIsTrue) checkedInvoiceIsTrueResult = false
        })
        if (!checkedInvoiceIsTrueResult && isShowDeliveryInfo.value) {
          notification.info({
            message: t('SAVCheck.infoTitle'),
            description: t('createRMA.pleaseCheckInvoiceAgain')
          })
        }
      }

      return {
        isBlankRMA,
        chooseFileName,
        covertToCamelCase,
        isChinaShop,
        formState,
        originalSt,
        modalAddProduct,
        newProduct,
        searchClient, onSearch, onSelect,
        options, modalProductSize, EnableModalAddProduct, reasons,
        rules, formRef, rmaProducts, createRma2, isSearch, onDeleteProduct,
        ValidateProductform, ValidateOriginalOrderID, ValidateOrderNumber, ValidateMaintenanceAmount, ValidateWaivedFeeAndComments,
        ValidateDateform, showReasonsModal, showModalReasons, modalReasons, rma_reasons, loadingCreate,
        isClickCreate, locale, t, ChangeExpiryDate, AmountList, currency, ReasonCheckbox, calcAmountList,
        calcReasons,
        confirmWaivedFee,
        CountryCodeData,
        filterOption,
        handleAdd,
        DateLessthan12Month,
        countryList,
        languageList,
        provinceOptionsArr,
        isShowDeliveryInfo,
        isShowToCustomer,
        isMustSendToCustomer,
        isOverSeaBoutique,
        boutiqueCountryList,
        handleBoutiqueCountry,
        handleChecked,
        handleCascader,
        isShowReceiver,
        checkSequenceNo,
        StreamBarcodeReader,
        BarcodeReader,
        showScanner,
        onLoaded,
        onDecode,
        onCloseScanner,
        toggleScanner,
        submitSelectReasons,
        cancelSelectReasons,
        onCheckInvoice
      };
    },
    methods: {
      uploadFile: async (event, record) => {
        for(let i = 0; i < event.target.files.length; i++) {
          if (process.env.VUE_APP_ENV === "local") {
            // event.target.files[i].name = "test-" + event.target.files[i].name
          }

          const compressedImage = await compressImage(event.target.files[i])
          console.log(compressedImage)
          // record.uploadImage.push(compressedImage, event.target.files[i].name)
          record.uploadImage.push(compressedImage)
        }

        // async function compressImage(img) {
        //   const options = {
        //     maxSizeMB: .5,
        //     maxWidthOrHeight: 1920,
        //     useWebWorker: true
        //   }

        //   const compressedimg = await imageCompression(img, options);

        //   return compressedimg
        // }

      },
      uploadGoldWeightFile: async (event, record) => {
        for(let i = 0; i < event.target.files.length; i++) {
          const compressedImage = await compressImage(event.target.files[i])
          record.uploadGoldWeightImage.push(compressedImage)
        }

        // async function compressImage(img) {
        //   const options = {
        //     maxSizeMB: .5,
        //     maxWidthOrHeight: 1920,
        //     useWebWorker: true
        //   }
        //   const compressedimg = await imageCompression(img, options);
        //   return compressedimg
        // }
      },
      cancel() {
        this.$emit('nextStep', 0)
      }
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
.custom-file-input {
  color: transparent;
  border-radius: 32px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: attr(data-content);
  color: white;
  display: inline-block;
  background: -webkit-linear-gradient(top, #001628, #001628);
  border-radius: 32px;
  padding: 4px 16px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  // height: 22px;
}
/*
.zh .custom-file-input::before {
  content: 'Choose files';
} */
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-file-upload {
  color: #fff;
  background-color: #001628;
  border-color: #001628;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  cursor: pointer;
  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 32px;
  display: inline-block;
  padding-top: 4px;
}
.ant-btn-primary {
  background-color: #001628;
  border-color: #001628;
}
.ant-switch-checked {
  background-color: #EA4435;
  background-color: #33A852;
}

.ant-switch-disabled {
  cursor: not-allowed;
  background-color: #33A852;
  opacity: 0.4;
}

// .ant-modal {
//     max-width: 100%;
//     max-height: 100%;
//     top: 0;
//     padding-bottom: 0;
//     margin: 0;
//   }
//   .ant-modal-content {
//     display: flex;
//     flex-direction: column;
//   }
//   .ant-modal-body {
//     flex: 1;
//     padding: 0;
//   }
//   .ant-modal-footer {
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     background-color: 'white';
//   }

.PillList-item {
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 12px 0 0;
  text-transform: capitalize;
}

.PillList-item input[type="checkbox"] {
  display: none;
}
.PillList-item input[type="checkbox"]:disabled + .PillList-label {
  border: 1px solid #b5babe;
  color: #b5babe;
}
.PillList-item input[type="checkbox"]:checked + .PillList-label {
  background-color: #00162A;
  border: 1px solid #00162A;
  color: #fff;
  padding-right: 16px;
  padding-left: 16px;
}
.PillList-label {
  border: 1px solid #00162A;
  border-radius: 20px;
  color: #00162A;
  display: block;
  padding: 7px 28px;
  text-decoration: none;
}
.PillList-item
  input[type="checkbox"]:checked
  + .PillList-label
  .Icon--checkLight {
  display: inline-block;
}
.PillList-item input[type="checkbox"]:checked + .PillList-label .Icon--addLight,
.PillList-label .Icon--checkLight,
.PillList-children {
  display: none;
}
.PillList-label .Icon {
  width: 12px;
  height: 12px;
  margin: 0 0 0 12px;
}
.Icon--smallest {
  font-size: 12px;
  line-height: 12px;
}
.Icon {
  background: transparent;
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  position: relative;
}
.ant-list-item {
  flex-wrap:wrap;
}
.list-content.comments {
  flex-basis:100%;
}
.item-comments {
  text-align:center;
}
.checkbox-reasons {
  // background: #001628;
  // color: white;
  background: #ffffff;
  color: #001628;
  border: 1px solid #001628;
  padding: 3px;
  border-radius: 20px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -16px;
}
.ant-checkbox-disabled:deep {
  color: green;
}
.ant-checkbox {
  display: none;
}
.reason_title {
  color: #f00;
  font-size: 20px;
  font-weight: 700;
  padding: 0 12px;
}
.renew_details {
  font-size: 20px;
  padding: 0 12px;
}
.renew_details_box {
  box-sizing: border-box;

  .renew_detail_btn {
    text-align: right;
    margin-top: 12px;
  }
}
.repair_fee_checkbox {
  padding-top: 10px;
}

.modal-with-height {
  .ant-modal-body {
    min-height:300px;
  }
}
.ant-input-search-enter-button {
  padding: 0px;
}
.loading {
  left: 46%;
  top: 50%;
  position: absolute;
}

.gold_product_info {
  text-align: center;
  color: darkgoldenrod;
}

.cart_info {
  line-height: 32px;
}
</style>

<style>
.ant-form-item-label {
  line-height: 32px;
}
.lable_no .ant-form-item-label>label:after{
  display: none !important;
}
.no_start .ant-form-item-label label::before{
    display: none !important;
  }
.no_start .ant-form-item-control-wrapper{
  display: flex;
  align-items: center;
}
.ant-input-disabled,
.ant-select-disabled .ant-select-selector {
  color: rgba(0, 0, 0, 0.85) !important;
}
/* .ant-form-item-label label::before, */
.requiredFormIcon label::before,
.requiredforitem,
.requiredspan::before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.sequence_no_input {
  padding: 0;
}
.boutique_country_red {
  margin-left: 10px;
  color: red;
}
.boutique_country_green {
  margin-left: 10px;
  color: green;
}
</style>